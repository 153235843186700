<template>
  <div class="general__survey">
    <section class="survey__form shadow-lg position-relative">
      <div
        v-if="!submitted"
      >
        <watermark :text="title" />
        <div
          class="survey__form-content"
        >
          <div class="section-pro">
            <b-form
              ref="general_survey_form"
              action=""
              class="p-5"
            >
              <div
                v-for="(fieldKeys, step) in steps"
                :key="step"
              >
                <div
                  v-if="currentStep === step"
                  class="row d-flex flex-wrap justify-content-between align-items-center"
                >
                  <form-fields
                    v-for="(field, index) in fieldKeys"
                    :key="index" 
                    class="my-1 col-12 w-100 px-lg-3"
                    :field="fields[field]"
                    :is-invalid="invalids[field]"
                    :invalid-message="invalids[field]"
                    :check-mate="validateField[field]"
                    :current-step="currentStep"
                    @update-checkbox="formSelect"
                    @validate-input="validate"
                  />
                </div>
              </div>
              <div class="col-md-3 ml-auto text-right">
                <div
                  class="btn-group"
                  role="group"
                  aria-label="Basic example"
                >
                  <button
                    v-if="!isFirstStep"
                    type="button"
                    class="btn btn-primary"
                    @click="previousStep"
                  >
                    Previous
                  </button>
                  <button
                    v-if="!isLastStep"
                    type="button"
                    class="btn btn-primary"
                    @click.prevent="nextStep"
                  >
                    Next
                  </button>
                  <button
                    v-else
                    type="submit"
                    class="btn btn-primary"
                    @click.prevent="submitGeneralSurvey"
                  >
                    Submit
                  </button>
                </div>
              </div>
            </b-form>
          </div>
        </div>
      </div>
      <div
        v-else
        class="p-5 col-md-10 mx-auto "
      >
        <success-message
          :message="success_message"
          :action="success_action"
          @close_survey_modal="handleCloseSurveyModal"
        />
      </div>
    </section>
  </div>
</template>

<script>
import FormFields from './partials/FormFields.vue';
import form_fields from './partials/form_fields';
import Watermark from './partials/Watermark.vue';
import SuccessMessage from './partials/SuccessMessage.vue';
import _ from 'lodash';

export default {
  name: 'GeneralSurvey',
    components: {
        FormFields,
        Watermark,
        SuccessMessage
    },

  emits: ['handle_survey_modal'],
 
  data: function() {
    return {
      title: '',
      success_message: '<h5>Thanks for taking the time to complete the <b>Ekiti State Housing Survey form</b>.</h5> <h5>You can proceed to Morgage calculator</h5>',
      success_action: {
        title: 'close',
        action: 'close_modal',
        url: '',
      },
      type: 'general_survey',
      currentStep: 0,
      fields: form_fields,
      invalids: {},
      submitted: false,
        steps: [
          [
              'do_you_own',
              'mortgage_familiarity',
              'mortgage_consideration',
              'mortgage_application',
              'mortgage_ownership_status',
          ],
          [
              'mortgage_constraints',
          ]
        ],
    }
  },

  computed: {
      totalSteps() {
          return this.steps.length -1;
      },
      currentFields() {
          return this.steps[this.currentStep];
      },
      isFirstStep() {
          return this.currentStep === 0;
      },
      isLastStep() {
          return this.currentStep === this.totalSteps;
      },
  },

  methods: {
    handleCloseSurveyModal() {
      this.$emit('handle_survey_modal')
    },
    
    formSelect(item) {
      var parent = item.parent;
      if(this.fields[parent].value.includes(item.slug)) {
        this.fields[parent].value=_.without(this.fields[parent].value,item.slug);
      } else {
        this.fields[parent].value.push(item.slug);
      }
    },

    isInvalid() {
        return Object.values(this.invalids).filter((key) => key).length;
    },

    nextStep() {
        if (this.isLastStep) return;
        this.validate();
        if(this.isInvalid()) return;
        this.currentStep++;
    },

    previousStep() {
        if (this.isFirstStep) return;
        this.invalids = {};
        this.currentStep--;
    },

    validate() {
        this.invalids = {};
        // validates all the fields on the current page
        this.currentFields.forEach((key) => {
            this.validateField(key);
        });
    },

    validateField(fieldKey) {
        this.invalids[fieldKey] = false;
        const field = this.fields[fieldKey];
        // run through each of the fields validation tests
        if(field.validations) {
          field.validations.forEach((validation) => {
            if(!validation.test(field.value)) {
                this.invalids[fieldKey] = validation.message;
            }
          });
        }
    },

    validateEmail(email) {
        const re = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
        return re.test(String(email).toLowerCase());
    },

    async submitGeneralSurvey() {
      this.validate();
      if(this.isInvalid()) return;
      this.submitted = true;

      this.$refs.general_survey_form.reset();

      // TODO: handle submit

      this.$store.dispatch('general/updateSurveyStatus', {
        survey: true,
      });
      location.reload();
    }
  }
}

</script>
<style lang="scss">
.general__survey {
  .survey__form {
    position: relative;
    .watermark__text {
      font-size: 60px;
    }
    &-content {
        z-index:1;
    }
    .survey__input {
      border: 2px solid gray;
      // height: 50px !important;
    }
}
}
</style>