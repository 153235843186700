
// state
export const state = {};

// getters
export const getters = {

    isAdmin(state, getters, rootState, rootGetters) {

        const isLoggedIn = rootGetters['auth/isAuthenticated'];
        if (!isLoggedIn) return false;
        const userRoles = rootState.auth.user.RoleIDs;
        return userRoles.includes('Admin');

    },
  
    isRealEstateDeveloper(state, getters, rootState, rootGetters) {

        const isLoggedIn = rootGetters['auth/isAuthenticated'];
        if (!isLoggedIn) return false;
        const userRoles = rootState.auth.user.RoleIDs;
        return userRoles.includes('Real Estate Developer');

    },

    isRegularUser(state, getters, rootState, rootGetters) {

        const isLoggedIn = rootGetters['auth/isAuthenticated'];
        if (!isLoggedIn) return false;
        const userRoles = rootState.auth.user.RoleIDs;
        return userRoles.includes('Regular User');
        
    },
};

// mutations
export const mutations = {};

// actions
export const actions = {};
