<template>
  <div class="survey__form-watermark">
    <div class="watermark__text">
      {{ text }}
    </div>
  </div>
</template>

<script>
export default {
    name: 'Watermark',
    props: {
        text: {
            type: String,
            default: 'HMIP',
        },
    },

}
</script>
<style lang="scss">
.survey__form {
    position: relative;
    &-watermark {
        position: absolute;
        z-index: 0;
        background:transparent;
        min-height: 100%; 
        min-width: 100%;
        display: flex;
        align-content: center;
        justify-content: center;
        align-items: center;
        .watermark__text {
            color:rgb(238, 238, 238);
            font-size: 30px;
            transform:rotate(320deg);
            -webkit-transform:rotate(320deg);
        }
    }
}
</style>
a