<template>
  <div class="main__content">
    <div>
      <Header
        @toggle-survey-modal="handleToggleSurveyModal"
      />
    </div>
    <div class="content-wrapper main__content_wrapper">
      <div class="content-body h-100">
        <main class="h-100">
          <slot>
            <router-view />
          </slot>
          <SurveyModal
            :visible="showSurveyModal"
            @close-survey-modal="handleCloseSurveyModal"  
          />
        </main>
      </div>
    </div>
    <!-- <RecentUpdates /> -->
    <div class="main__content_footer">
      <Footer />
      <Cookie />
    </div>
  </div>
</template>
<script>
import Cookie from '../components/General/Cookie.vue';
import Footer from '../components/General/Footer.vue'
import Header from '../components/General/Header.vue'
// import RecentUpdates from '../components/General/RecentUpdates.vue';
import SurveyModal from '../components/General/SurveyModal.vue';
// import '../assets/sales-notification.js'

export default {
  name: "Default",
  components: {
    Header,
    Footer,
    SurveyModal,
    Cookie,
    // RecentUpdates,
  },

  data () {
    return {
      showSurveyModal: false,
    };
  },

  methods: {
    handleToggleSurveyModal() {
      this.showSurveyModal = !this.showSurveyModal;
    },

    handleCloseSurveyModal() {
      this.showSurveyModal = false;
    }
  }

}
</script>
<style src="vue-multiselect/dist/vue-multiselect.min.css"></style>
<style>
  .main__content {
    height: 100vh;
    min-height: 100%;
    display: flex;
    flex-direction: column;
    align-items: stretch;
  }
  .main__content_wrapper {
    flex-grow: 1;
  }
  .main__content_footer {
    flex-shrink: 0;
  }
</style>