import Vue from 'vue'
import store from '@/store';
import Meta from 'vue-meta';
import routes from './routes';
import Router from 'vue-router'
// import NProgress from 'nprogress';
import { sync } from 'vuex-router-sync';
import { get } from 'lodash';

Vue.use(Meta);
Vue.use(Router)

const router = createRouter();

export default router;

sync(store, router);
/**
 * Create a new router instance.
 *
 * @return {Router}
 */
function createRouter() {
  const router = new Router({
    scrollBehavior,
    mode: 'history',
    // base: process.env.VUE_BASE_URL,
    routes,
  });

  router.beforeEach(beforeEach);
  router.afterEach(afterEach);
  router.beforeResolve(beforeResolve);

  return router;
}

/**
 * Global router guard.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
 async function beforeEach(to, from, next) {
  try {
    // if (to.name) {
    //   NProgress.start();
    // }
    if (to.fullPath === from.fullPath) {
      return next(false);
    } 

    const isAuthenticated = store.getters['auth/isAuthenticated'];
    const isAdmin = store.getters['accessControl/isAdmin'];
    const isRealEstateDeveloper = store.getters['accessControl/isRealEstateDeveloper'];
    const isRegularUser = store.getters['accessControl/isRegularUser'];

    if(isAuthenticated) {

      if(to.matched.find(record => get(record, 'meta.type') === 'admin-container')) {

        if(!isAdmin) {
          return next({ name: 'home' });
        }
        return next()

      }

      if(to.matched.find(record => get(record, 'meta.type') === 'real-estate-container')) {

        if(!isRealEstateDeveloper) {
          return next({ name: 'home' });
        }
        return next()
      }

      if(to.matched.find(record => get(record, 'meta.type') === 'user-container')) {

        if(!isRegularUser) {
          return next({ name: 'home' });
        }
        return next()
      }

    }

    if (to.matched.some(record => get(record, 'meta.type') === 'auth-container') && isAuthenticated) {
      return next({ name: 'home' });
    }

    if (to.matched.some((record) => record.meta.requiresAuth) && !isAuthenticated
    ) {
      return next({ name: 'auth.login', query: {redirect: to.path }});
    }

    return next();

  } catch (e) {
    console.dir(e);
  }

}

/**
 * Global before resolve hook.
 * Called right before the navigation is confirmed,
 * after all in-component guards and async route components are resolved.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
 async function beforeResolve(to, from, next) {
  const currentLayout = to.meta.layout || 'default';
  await store.dispatch('layout/setCurrent', currentLayout);

  store.dispatch('layout/updateReadyState', true);

  next();
}

/**
 * Global after hook.
 *
 * @param {Route} to
 * @param {Route} from
 * @param {Function} next
 */
 async function afterEach() {
  // NProgress.done();
}

/**
 * Scroll Behavior
 *
 * @link https://router.vuejs.org/en/advanced/scroll-behavior.html
 *
 * @param  {Route} to
 * @param  {Route} from
 * @param  {Object|undefined} savedPosition
 * @return {Object}
 */
 function scrollBehavior(to, from, savedPosition) {
  if (to.query.read || from.query.read) {
    // Do not scroll
    return false;
  }

  if (savedPosition) {
    return savedPosition;
  }

  if (to.hash) {
    return { selector: to.hash };
  }

  const [component] = router.getMatchedComponents({ ...to }).slice(-1);

  if (component && component.scrollToTop === false) {
    return {};
  }

  return new Promise(resolve => {
    setTimeout(() => {
      resolve({ x: 0, y: 0 });
    });
  });
}
