const OwnAHome = () => import(/* webpackChunkName: "guest" */ '../../views/property/Own-a-home.vue').then(m => m.default || m);
const SubmitProperty = () => import(/* webpackChunkName: "guest" */ '../../views/property/SubmitProperty.vue').then(m => m.default || m);
const Steps = () => import(/* webpackChunkName: "guest" */ '../../views/property/Steps.vue').then(m => m.default || m);
const FHFCalculator = () => import(/* webpackChunkName: "guest" */ '../../views/property/FHFCalculator.vue').then(m => m.default || m);
const DetailedMortgageCalculator = () => import(/* webpackChunkName: "guest" */ '../../views/property/DetailedMortgageCalculator.vue').then(m => m.default || m);

export default [
  {
    path: '/own-a-home',
    name: 'own.home',
    component: OwnAHome,
    meta: {
      layout: 'default',
    }
  },
  {
    path: '/submit-a-property',
    name: 'submit.property',
    component: SubmitProperty,
    meta: {
      layout: 'default',
    }
  },
  {
    path: '/steps-to-own-a-home',
    name: 'steps',
    component: Steps,
    meta: {
      layout: 'default',
    }
  },
  {
    path: '/FHF-calculator',
    name: 'fhf.calculator',
    component: FHFCalculator,
    meta: {
      layout: 'default',
    }
  },
  {
    path: '/mortgage-calculator',
    name: 'mortgage.calculator',
    component: DetailedMortgageCalculator,
    meta: {
      layout: 'default',
    }
  },
]