<template>
  <footer class=" " data-midnight="white">
    <div class="py-4 px-2 md:px-8">
      <div
        class="flex flex-col md:flex-row gap-10 items-start text-white text-sm md:mx-20 mx-5 mt-12"
      >
        <div class="w-full md:w-1/3">
          <h4 class="pb-4 font-bold">About</h4>
          <p class="w-full text-white md:w-9/12">
            The Housing Market Information Portal (HMIP) provides reliable and
            convenient access to the latest housing market information in
            Nigeria.
          </p>
        </div>
        <div class="w-full md:w-1/3">
          <h4 class="pb-4 font-bold">Account Links</h4>
          <ul class="flex flex-col gap-2">
            <li>
              <a :href="'/property-own-a-home'" style="color: white">
                Own a home
              </a>
            </li>
            <li>
              <a :href="'/own-a-home'" style="color: white">
                Steps to Own a Home
              </a>
            </li>
            <li>
              <a :href="'/mortgage-calculator'" style="color: white">
                Detailed Mortgage Calculator
              </a>
            </li>

            <li>
              <a :href="'/dashboard/home'" style="color: white">Dashboard </a>
            </li>
            <li>
              <a :href="'/news'" style="color: white"> News </a>
            </li>
            <li>
              <a :href="'/contact-us'" style="color: white"> Contact </a>
            </li>
            <li>
              <a :href="'/survey/own-a-home'" style="color: white"
                >Survey Participation
              </a>
            </li>
          </ul>
        </div>
        <div class="w-full md:w-1/3">
          <h4 class="pb-4 font-bold">Contact Us</h4>
          <ul class="flex flex-col gap-2 pb-8">
            <li>
              <i class="ti-email" />
              info@nmrc.com.ng
            </li>
            <li>
              <i class="ti-location-pin" />
              <span>
                17, Sanusi Fafunwa Street, Victoria Island,
                <br />
                Lagos Nigeria
              </span>
            </li>
            <li class="pb-4">
              <i class="ti-headphone" />
              +234 (0) 913 957 6829.
            </li>
          </ul>
        </div>
      </div>

      <div class="py-2 md:mx-20 mx-5 mt-10">
        <div
          class="flex w-full gap-6 md:text-left text-center flex-col md:flex-row bg-gray-400 px-0 md:px-4 py-2 items-center justify-between"
        >
          <p class="w-full md:w-1/3 pl-0 md:pl-6 text-[#276C43]">
            Newsletter Sign up
          </p>
          <div
            class="bg-white flex-col md:flex-row flex px-4 justify-between w-full md:w-2/3 rounded-lg py-2"
          >
            <input
              type="text"
              placeholder="Your Email Address"
              class="text-black text-center"
            />
            <button class="bg-green-700 py-1 px-8 rounded-md text-white">
              Submit
            </button>
          </div>
        </div>
      </div>

      <div class="text-center text-white pt-10 pb-20">
        <p class="text-white">
          Copyright © 2024, Nigeria Mortgage Refinance Company.
        </p>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
  name: "Footer",
};
</script>
