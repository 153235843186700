<template>
  <div>
    <div class="content-wrapper">
      <div class="content-body">
        <main>
          <slot>
            <router-view />
          </slot>
        </main>
      </div>
    </div>
  </div>
</template>

<script>
import '../styles/admin/main.css'
export default {
  name: "BareLayout",
}
</script>
