<template>
  <div
    :id="'container-'+field.slug"
    class="p-2 animated"
    data-animation="fadeInUpShorter"
    data-animation-delay="0.8s"
  >
    <template
      v-if="field.type_slug === 'checkbox' || field.type_slug === 'radio'"
    >
      <div
        :class="{'is-invalid' : isInvalid}"
      >
        <label
          :for="field.slug"
          class="survey__input-label font-weight-bold"
        >
          {{ field.label }}
        </label>
        <div
          v-if="field.type_slug === 'radio'"
          class="d-flex justify-content-around mt-2"
        >
          <div
            v-for="(option, index) in field.options"
            :key="index"
            class="form-check"
          >
            <input
              :id="option+index"
              v-model="field.value"
              :type="field.type"
              :data-type="field.slug"
              :name="field.slug"
              :value="option"
              :class="{'is-invalid' : isInvalid}"
              :data-type-slug="field.type_slug"
              class="form-check-input survey__input mb-2 text-capitalize"
              required
              @input="validate"
            >
            <label
              :for="option+index"
              class="form-check-label survey__input-label"
            >
              {{ option }}
            </label>
          </div>
        </div>
        <div
          v-if="field.type_slug === 'checkbox'"
          class="d-inline-block"
        >
          <div
            v-for="(option, o) in field.options"
            :key="option+o"
            class="form-check mb-2"
          >
            <input
              :id="option.value+o"
              :type="field.type"
              :value="option.value"
              :data-type="field.slug"
              :data-type-slug="field.type_slug"
              class="form-check-input survey__input mb-2"
              :class="{'is-invalid' : isInvalid}"
              :checked="field.value.includes(option.slug)"
              @input="validate"
              @click="selectItem(option)"
            >
            <label
              :for="option.value+o"
              class="form-check-label survey__input-label"
            >
              {{ option.title }}
            </label>
          </div>
        </div>
      </div>
    </template>
    <template
      v-if="checkAcceptability(field.type_slug)"
    >
      <label
        :for="field.slug"
        class="survey__input-label font-weight-bold"
      >
        {{ field.label }}
      </label>
      <select
        v-if="field.type === 'select'"
        :id="field.slug"
        v-model="field.value"
        class="form-control survey__input mb-2"
        :class="{'is-invalid' : isInvalid}"
        :data-type="field.slug"
        @change="fetchLGAbyState(field.value, field.slug)"
      >
        <option
          value=""
          selected
          disabled
        >
          ...
        </option>
        <option
          v-for="(option, index) in field.options"
          :key="index"
          :value="option.value"
        >
          {{ option.label }}
        </option>
      </select>
      <input
        v-else
        :id="field.slug"
        v-model="field.value"
        :type="field.type"
        :data-type="field.slug"
        :data-type-slug="field.type_slug"
        class="form-control survey__input mb-2"
        :class="{'is-invalid' : isInvalid}"
        min="1"
        :placeholder="field.placeholder"
        required
        @keydown="formatAmount"
        @input="validate"
      >
      <div
        v-if="isInvalid"
        class="invalid-feedback"
      >
        {{ invalidMessage }}
      </div>
    </template>
  </div>
</template>
<script>
import { debounce } from 'lodash';
import axios from 'axios';
import stateData from './state_data';

export default {
    name: "FormFields",
    props: {
        field: {
            type: Object,
            default: () => {},
        },
        isInvalid: {
            type: [Boolean, String],
            default: '',
        },
        invalidMessage: {
            type: [Boolean, String],
            default: '',
        },
        currentStep: {
            type: Number,
            default: 0,
        },
        type: {
            type: String,
            default: 'survey',
        },
    },

    emits: ['update-checkbox', 'format-value', 'validate-input'],

    data: function() {
      return {
        lgas: [],
      };
    },

    mounted() {
      // fetch lga
      const hasLGA = ['state', 'property_state', 'employer_state'];
      if(this.field.slug && hasLGA.includes(this.field.slug) && this.field.value) {
        this.fetchLGAbyState(this.field.value, this.field.slug);
      }
      
    },

    methods: {
      checkAcceptability(type_slug){
        var acceptables = ['text', 'select', 'number', 'amount'];
        return acceptables.includes(type_slug);
      },

      selectItem(option) {
        this.$emit('update-checkbox', option)
      },

      formatAmount: debounce(function(e) {
        var type = e.target.getAttribute('data-type-slug');
        if(type === 'amount') {
          let value = '';
          let input = this.field.value;
          if(!isNaN(input)) {
            const fm = this.field.value.replace(/[^\d,]/g, '');
            value = parseFloat(fm).toFixed(2).replace(/\d(?=(\d{3})+\.)/g, '$&,');
          }
          this.field.value = value;
        }
      }, 500),


      validate() {
        this.$nextTick(() => {
            if(this.isInvalid){
              this.$emit('validate-input', this.field);
            }
        });
      },

      async fetchLGA(state_id){
        let lga = '';

        await axios.get(`/Lga/fetch_by_state/${state_id}`)
          .then(response => {
            if(response.status === 200) {
              lga = response.data.details;
            }
          })
          .catch(error => (
            this.$swal({
                icon: 'error',
                title: 'OOPS! Something went wrong.',
                text: 'HINT: Refresh and try again'
            })
          ));

          return lga;
      },

      async fetchLGAbyState(value, type) {
        this.validate();
        switch (type) {
          case 'state':
            var lgaObj = document.getElementById('state_lga') || false;

            if(lgaObj) {
              lgaObj.options.length = 1;

              switch (this.type) {
                case 'own_a_home':
                  var lgas = stateData[value];
                  if(lgas) {
                    lgas.map(lga => {
                      lgaObj.options[lgaObj.options.length] = new Option(lga, lga);
                    })
                  }
                  break;
              
                default:
                  var lgas = await this.fetchLGA(value);
                  if(lgas) {
                    lgas.map(lga => {
                      lgaObj.options[lgaObj.options.length] = new Option(lga.LgaName, lga.id);
                    })
                  }
                  break;
              }
            }
            break;

          case 'property_state':
          var propertyLgaObj = document.getElementById('property_lga') || false;

            if(propertyLgaObj) {
              propertyLgaObj.options.length = 1;

              switch (this.type) {
                case 'own_a_home':
                  var property_lga = stateData[value];
                  if(property_lga) {
                    property_lga.map(lga => {
                      propertyLgaObj.options[propertyLgaObj.options.length] = new Option(lga, lga);
                    })
                  }
                  break;
              
                default:
                var property_lga = await this.fetchLGA(value);
                  if(property_lga) {
                    property_lga.map(lga => {
                      propertyLgaObj.options[propertyLgaObj.options.length] = new Option(lga.LgaName, lga.id);
                    })
                  }
                  break;
              }
            }
            break;

          case 'employer_state':
            var employerLgaObj = document.getElementById('employer_lga') || false;

            if(employerLgaObj) {
              employerLgaObj.options.length = 1;

              switch (this.type) {
                case 'own_a_home':
                  var employer_lga = stateData[value];
                  if(employer_lga) {
                    employer_lga.map(lga => {
                      employerLgaObj.options[employerLgaObj.options.length] = new Option(lga, lga);
                    })
                  }
                  break;
              
                default:
                  var employer_lga = await this.fetchLGA(value);
                  if(employer_lga) {
                    employer_lga.map(lga => {
                      employerLgaObj.options[employerLgaObj.options.length] = new Option(lga.LgaName, lga.id);
                    })
                  }
                  break;
              }
            }
            break;
        }
      },
    }
}
</script>