<template>
  <footer class="sticky-footer bg-white">
    <div class="container my-auto">
      <div class="copyright text-center my-auto">
        <span>
          Copyright &copy; HMIP | NMRC {{ new Date().getFullYear() }}
        </span>
      </div>
    </div>
  </footer>
</template>

<script>
export default {
    name: 'AdminFooter',
    data: function() {
      return {}
    }
}
</script>