const SurveyNREDC = () => import(/* webpackChunkName: "guest" */ '../../views/survey/NREDC.vue').then(m => m.default || m);
const SurveyREDAN = () => import(/* webpackChunkName: "guest" */ '../../views/survey/REDAN.vue').then(m => m.default || m);
const SurveyAbuja = () => import(/* webpackChunkName: "guest" */ '../../views/survey/AbujaHousing.vue').then(m => m.default || m);

const SurveyMarket = () => import(/* webpackChunkName: "guest" */ '../../views/survey/Market.vue').then(m => m.default || m);
const SurveyEkiti = () => import(/* webpackChunkName: "guest" */ '../../views/survey/Ekiti.vue').then(m => m.default || m);
const SurveyOwnAHome = () => import(/* webpackChunkName: "guest" */ '../../views/survey/OwnAHome.vue').then(m => m.default || m);

export default [

 
    {
        path: 'NREDC',
        name: 'survey.nredc',
        component: SurveyNREDC,
        meta: {
          layout: 'default',
        },
    },
    {
      path: 'AbujaHousing',
      name: 'survey.abujaHousing',
      component:SurveyAbuja ,
      meta: {
        layout: 'default',
      },
  },
    {
      path: 'REDAN',
      name: 'survey.redan',
      component: SurveyREDAN,
      meta: {
        layout: 'default',
      },
  },
    {
        path: 'market',
        name: 'survey.market',
        component: SurveyMarket,
        meta: {
          layout: 'default',
        },
    },
    {
        path: 'ekiti',
        name: 'survey.ekiti',
        component: SurveyEkiti,
        meta: {
          layout: 'default',
        },
    },
    {
      path: 'own-a-home',
      name: 'survey.own.home',
      component: SurveyOwnAHome,
      meta: {
        layout: 'default',
      },
  },
]