const validateEmail = /^(([^<>()[\]\\.,;:\s@"]+(\.[^<>()[\]\\.,;:\s@"]+)*)|(".+"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;

const fields = {
    Salutation: {
        label: 'salutation',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Mr',
                value: 'mr'
            },
            {
                label: 'Ms',
                value: 'ms'
            },
            {
                label: 'Mrs',
                value: 'mrs'
            },
            {
                label: 'Miss',
                value: 'miss'
            },
            {
                label: 'Dr',
                value: 'dr'
            },
            {
                label: 'Rev',
                value: 'rev'
            },
            {
                label: 'Judge',
                value: 'judge'
            },
            {
                label: 'Engr',
                value: 'engr'
            },
            {
                label: 'Prof',
                value: 'prof'
            }
        ],
        slug: 'salutation',
        validations: [
            {
                message: "Salutation is required",
                test: (value) => value
            }
        ]
    },
    LastName: {
        label: 'last name',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Last Name',
        slug: 'last_name',
        validations: [
            {
                message: "Last Name is required",
                test: (value) => value
            }
        ]
    },
    FirstName: {
        label: 'first name',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'First Name',
        slug: 'first_name',
        validations: [
            {
                message: "First Name is required",
                test: (value) => value
            }
        ]
    },
    MiddleName: {
        label: 'middle name',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Middle Name',
        slug: 'middle_name',
    },
           // validations: [
        //     {
        //         message: "Middle Name is required",
        //         test: (value) => value
        //     }
        // ]
    Email: {
        label: 'email address',
        value: '',
        type: 'email',
        type_slug: 'text',
        placeholder: 'Email Address',
        slug: 'email_address',
        validations: [
            {
                message: "Must be a valid email address",
                test: (value) => validateEmail.test(String(value).toLowerCase()),
            },
            {
                message: "Email is required",
                test: (value) => value
            }
        ]
    },
    PhoneNumber: {
        label: 'phone number',
        value: '',
        type: 'tel',
        type_slug: 'text',
        placeholder: '+234 812 3456 789',
        slug: 'phone_number',
        validations: [
            {
                message: "Phone Number is required",
                test: (value) => value
            }
        ]
    },
    DateOfBirth: {
        label: 'date of birth',
        value: '',
        type: 'date',
        type_slug: 'text',
        placeholder: ' ',
        slug: 'date_of_birth',
        validations: [
            {
                message: "Date of Birth is required",
                test: (value) => value
            }
        ]
    },
    SexID: {
        label: 'gender',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Male',
                value: 'm'
            },
            {
                label: 'Female',
                value: 'f'
            }
        ],
        slug: 'gender',
        validations: [
            {
                message: "Gender is required",
                test: (value) => value
            }
        ]
    },

    Dependents: {
        label: 'no of dependents',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: '0',
        slug: 'number_of_dependents',
        validations: [
            {
                message: "Number of Dependents must be a valid number",
                test: (value) => !isNaN(value)
            },
            {
                message: "Number of Dependents is required",
                test: (value) => value
            }
        ]
    },
    Enumerator: {
        label: 'enumerator',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Victor Tomiwa Olawoye',
                value: 'Victor Tomiwa Olawoye'
            },
            {
                label: 'Oladele Dare',
                value: 'Oladele Dare'
            },
            {
                label: 'Samuel Ubaka',
                value: 'Samuel Ubaka'
            },
            {
                label: 'David Salako',
                value: 'David Salako'
            },
            {
                label: 'Ayodeji Ojo',
                value: 'Ayodeji Ojo'
            },
            {
                label: 'Kemi Abu',
                value: 'Kemi Abu'
            },
            {
                label: 'Usoro Asia',
                value: 'Usoro Asia'
            },
            {
                label: 'Israel Adamu',
                value: 'Israel Adamu'
            },
            {
                label: 'Abdullahi Bello',
                value: 'Abdullahi Bello'
            },
            {
                label: 'Alozie Sophia',
                value: 'Alozie Sophia'
            },
            {
                label: 'Ibinuwa Odunayo',
                value: 'Ibinuwa Odunayo'
            },
            {
                label: 'Joseph Edina',
                value: 'Joseph Edina'
            },
            {
                label: 'Nkpa Oluchukwu',
                value: 'Nkpa Oluchukwu'
            },
            {
                label: 'Tarachi Felix',
                value: 'Tarachi Felix'
            },
            {
                label: 'Olatunde Elizabeth',
                value: 'Olatunde Elizabeth'
            },
            {
                label: 'Warri Victor',
                value: 'Warri Victor'
            },
            {
                label: 'Arugba Providence',
                value: 'Arugba Providence'
            },
            {
                label: 'Ojukwu Childers',
                value: 'Ojukwu Childers'
            },
            {
                label: 'Adebayo Kayode',
                value: 'Adebayo Kayode'
            },
            {
                label: 'Oshibugie Frederick',
                value: 'Oshibugie Frederick'
            },
            {
                label: 'Okafor Jennifer',
                value: 'Okafor Jennifer'
            },
            {
                label: 'Kolade Michael',
                value: 'Kolade Michael'
            },
            {
                label: 'Olorunda Bose',
                value: 'Olorunda Bose'
            },
            {
                label: 'Ileke Adedayo',
                value: 'Ileke Adedayo'
            },
            {
                label: 'Noriega Chinaza',
                value: 'Noriega Chinaza'
            },
            {
                label: 'Egberongbe Sunday',
                value: 'Egberongbe Sunday'
            },
            {
                label: 'Alikor Donates',
                value: 'Alikor Donates'
            },
            {
                label: 'Mani Bala',
                value: 'Mani Bala'
            },
            {
                label: 'Akatakpo Sixtus',
                value: 'Akatakpo Sixtus'
            },
            {
                label: 'Anyadi Onyeka',
                value: 'Anyadi Onyeka'
            },
            {
                label: 'Joseph Sunday',
                value: 'Joseph Sunday'
            },
            {
                label: 'Taiwo Victoria',
                value: 'Taiwo Victoria'
            },
            {
                label: 'Tosin Elijah',
                value: 'Tosin Elijah'
            },
            {
                label: 'Halidu Abubakar',
                value: 'Halidu Abubakar'
            },
            {
                label: 'Alhassan Fatima',
                value: 'Alhassan Fatima'
            },
            {
                label: 'Yahaya Amisetu',
                value: 'Yahaya Amisetu'
            },
            {
                label: 'Agbaje Suliyat',
                value: 'Agbaje Suliyat'
            },
            {
                label: 'Apata Chioma',
                value: 'Apata Chioma'
            },
            {
                label: 'Okpuru Gabriel',
                value: 'Okpuru Gabriel'
            },
            {
                label: 'Jimoh Abdulmalik',
                value: 'Jimoh Abdulmalik'
            },
            {
                label: 'Bello Jabir',
                value: 'Bello Jabir'
            },
            {
                label: 'Mogbolu Joy',
                value: 'Mogbolu Joy'
            },
            {
                label: 'Tobi Pete',
                value: 'Tobi Pete'
            }
        ],
        slug: 'enumerators',
        validations: [
            {
                message: "Enumerator is required",
                test: (value) => value
            }
        ]
    },
    ProvinceOrState: {
        label: 'state',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [],
        slug: 'state',
        validations: [
            {
                message: "State is required",
                test: (value) => value
            }
        ]
    },
    LgaID: {
        label: 'LGA',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [],
        slug: 'state_lga',
        validations: [
            {
                message: "LGA is required",
                test: (value) => value
            }
        ]
    },
    CurrentAddress: {
        label: 'current address',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Current Address',
        slug: 'current_adddress',
        validations: [
            {
                message: "Current Address is required",
                test: (value) => value
            }
        ]
    },
    RentAmountPerYear: {
        label: 'Rent Per Year (naira)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        placeholder: '0',
        slug: 'rent_amount_per_year',
    },
    EmployerState: {
        label: 'employer state',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [''],
        slug: 'employer_state',
        validations: [
            {
                message: "Employer State is required",
                test: (value) => value
            }
        ]
    },
    EmployerLga: {
        label: 'employer LGA',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [],
        slug: 'employer_lga',
        validations: [
            {
                message: "Employer LGA is required",
                test: (value) => value
            }
        ]
    },
    Location: {
        label: 'location or area',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Location or Area',
        slug: 'location_of_area',
        validations: [
            {
                message: "Location or Area is required",
                test: (value) => value
            }
        ]
    },
    StreetName: {
        label: 'street name',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Street Name',
        slug: 'street_name',
        validations: [
            {
                message: "Street Name is required",
                test: (value) => value
            }
        ]
    },
    StreetNumber: {
        label: 'street number',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Street Number',
        slug: 'street_number',
        validations: [
            {
                message: "Street Number is required",
                test: (value) => value
            }
        ]
    },
    OfficePlot: {
        label: 'office plot',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Plot 105',
        slug: 'office_plot',
        validations: [
            {
                message: "Office Plot is required",
                test: (value) => value
            }
        ]
    },
    Unit: {
        label: 'unit',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: '0 bedroom',
        slug: 'unit',
        validations: [
            {
                message: "Unit is required",
                test: (value) => value
            }
        ]
    },
    Estate: {
        label: 'estate name',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Lekki',
        slug: 'estate',
        validations: [
            {
                message: "Estate Name is required",
                test: (value) => value
            }
        ]
    },
    LengthOfTimeSpentIndwelling: {
        label: 'duration of residence',
        value: '',
        type: 'number',
        type_slug: 'number',
        placeholder: '0',
        slug: 'duration_of_residence',
        validations: [
            {
                message: "Duration of Residence is required",
                test: (value) => value
            },
            {
                message: "Duration of Residence must be a valid number",
                test: (value) => !isNaN(value)
            },
        ]
    },
    HouseNumber: {
        label: 'house number',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: '0',
        slug: 'house_number',
        validations: [
            {
                message: "House Number is required",
                test: (value) => value
            }
        ]
    },
    OwnershipTypeID: {
        label: 'ownership type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Rent',
                value: 'Rent'
            },
            {
                label: 'Owner',
                value: 'Owner'
            },
            {
                label: 'Live with family / friend',
                value: 'Live with family / friend'
            },
        ],
        slug: 'ownership_type',
        validations: [
            {
                message: "Ownership Type is required",
                test: (value) => value
            }
        ]
    },
    PropertyValue: {
        label: 'property value (naira)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        placeholder: '0',
        slug: 'property_value',
    },
    Purchase: {
        label: 'purchase Amount (naira)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        placeholder: '0',
        slug: 'purchase_amount',
        validations: [
            {
                message: "Purchase Amount is required",
                test: (value) => value
            }
        ]
    },
    Deposit: {
        label: 'Deposit Amount (naira)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        placeholder: '0',
        slug: 'deposit_amount',
        validations: [
            {
                message: "Deposit Amount is required",
                test: (value) => value
            }
        ]
    },
    TermMonths: {
        label: 'term months',
        value: '',
        type: 'number',
        type_slug: 'number',
        placeholder: '0',
        slug: 'term_months',
        validations: [
            {
                message: "Term Months is required",
                test: (value) => value
            }
        ]
    },
    BVN: {
        label: 'bvn',
        value: '',
        type: 'number',
        type_slug: 'number',
        placeholder: '0',
        slug: 'bvn',
        validations: [
            {
                message: "BVN is required",
                test: (value) => value
            }
        ]
    },
    PencomPin: {
        label: 'pencom pin',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: '0',
        slug: 'bvn',
        validations: [
            {
                message: "Pencom Pin is required",
                test: (value) => value
            }
        ]
    },
    WhatIsYourPrimaryOccupation: {
        label: 'occupation',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Clerical',
                value: 'clerical'
            },
            {
                label: 'Labour/Tradesperson',
                value: 'trades'
            },
            {
                label: 'Retired',
                value: 'retired'
            },
            {
                label: 'Professional',
                value: 'profess'
            },
            {
                label: 'Civil Servant',
                value: 'civil'
            },
            {
                label: 'Self-Employed',
                value: 'self'
            },
            {
                label: 'Other',
                value: 'other'
            }
        ],
        slug: 'occupation',
        validations: [
            {
                message: "Occupation is required",
                test: (value) => value
            }
        ]
    },
    NameOfEmployer: {
        label: 'name of employer',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Employer',
        slug: 'name_of_employer',
        validations: [
            {
                message: "Name of Employer is required",
                test: (value) => value
            }
        ]
    },
    EmployeeID: {
        label: 'employee id',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'EM123456789',
        slug: 'employee_id',
        validations: [
            {
                message: "Employee Id is required",
                test: (value) => value
            }
        ]
    },
    EmploymentDate: {
        label: 'date of employment',
        value: '',
        type: 'date',
        type_slug: 'text',
        placeholder: ' ',
        slug: 'employment_date',
        validations: [
            {
                message: "Date of Employment is required",
                test: (value) => value
            }
        ]
    },
    EmploymentSectorID: {
        label: 'employement sector',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Government',
                value: 'Government',
            },
            {
                label: 'Private',
                value: 'Private',
            },
            {
                label: 'Public-Private Partnership',
                value: 'Public-Private Partnership',
            },
            {
                label: 'Others',
                value: 'Others',
            },
        ],
        slug: 'employment_sector',
        validations: [
            {
                message: "Employement Sector is required",
                test: (value) => value
            }
        ]
    },
    Designation: {
        label: 'designation',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Designation',
        slug: 'designation',
        validations: [
            {
                message: "Designation is required",
                test: (value) => value
            }
        ]
    },
    EmployeeEmail: {
        label: 'official email address',
        value: '',
        type: 'email',
        type_slug: 'text',
        placeholder: 'Email Address',
        slug: 'email_address',
        validations: [
            {
                message: "Must be a valid email address",
                test: (value) => validateEmail.test(String(value).toLowerCase()),
            },
            {
                message: "Official Email is required",
                test: (value) => value
            }
        ]
    },
    EmploymentType: {
        label: 'employment type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Permanent full time',
                value: 'fulltime'
            },
            {
                label: 'Permanent part time',
                value: 'parttime'
            },
            {
                label: 'Permanent - Seasonal',
                value: 'seasonal'
            },
            {
                label: 'Temporary full time',
                value: 'tempfulltime'
            },
            {
                label: 'Temporary part time',
                value: 'tempparttime'
            },
            {
                label: 'Temporary - Seasonal',
                value: 'tempseasonal'
            },
        ],
        slug: 'employment_type',
        validations: [
            {
                message: "Employement Type is required",
                test: (value) => value
            }
        ]
    },
    EmploymentStatusID: {
        label: 'employment status',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Current',
                value: 'current'
            },
            {
                label: 'Previous',
                value: 'previous'
            }
        ],
        slug: 'employment_status',
        validations: [
            {
                message: "Employement Status is required",
                test: (value) => value
            }
        ]
    },
    IncomeAmount: {
        label: 'income amount',
        value: '',
        type: 'text',
        type_slug: 'amount',
        placeholder: '0',
        slug: 'income_amount',
        validations: [
            {
                message: "Income Amount is required",
                test: (value) => value
            }
        ]
    },
    MonthlyIncome: {
        label: 'monthly income / salary',
        value: '',
        type: 'text',
        type_slug: 'amount',
        placeholder: '0',
        slug: 'monthly_income',
        validations: [
            {
                message: "Monthly Income / Salary is required",
                test: (value) => value
            }
        ]
    },
    IncomeType: {
        label: 'income type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Salary',
                value: 'salary'
            },
            {
                label: 'Investment',
                value: 'investment'
            },
            {
                label: 'Support',
                value: 'support'
            },
            {
                label: 'Alimony',
                value: 'alimony'
            },
            {
                label: 'Other Income',
                value: 'otherincome'
            },
            {
                label: 'Car Allowance',
                value: 'car allowance'
            },
            {
                label: 'Pension',
                value: 'pension'
            },
        ],
        slug: 'income_type',
        validations: [
            {
                message: "Income Type is required",
                test: (value) => value
            }
        ]
    },
    IncomePeriod: {
        label: 'income period',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Annual',
                value: 'Annual'
            },
            {
                label: 'Semi-Annual',
                value: 'Semi-Annual'
            },
            {
                label: 'Quarterly',
                value: 'Quarterly'
            },
            {
                label: 'Monthly',
                value: 'Monthly'
            },
            {
                label: 'Semi-Monthly',
                value: 'Semi-Monthly'
            },
            {
                label: 'Bi-Weekly',
                value: 'Bi-Weekly'
            },
            {
                label: 'Weekly',
                value: 'Weekly'
            }
        ],
        slug: 'income_period',
        validations: [
            {
                message: "Income Period is required",
                test: (value) => value
            }
        ]
    },
    SecondaryIncome: {
        label: 'secondary / other income type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Income from Spouse',
                value: 'Income from Spouse'
            },
            {
                label: 'Investment/Business',
                value: 'Investment/Business'
            },
            {
                label: 'Foreign Remittance',
                value: 'Foreign Remittance'
            },
        ],
        slug: 'secondary_income',
        validations: [
            {
                message: "Secondary Income Type is required",
                test: (value) => value
            }
        ]
    },
    SecondaryIncomeAmount: {
        label: 'secondary income (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'secondary_income',
        validations: [
            {
                message: "Secondary Income Amount is required",
                test: (value) => value
            }
        ]
    },
    OtherIncomePeriod: {
        label: 'other income period',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Annual',
                value: 'Annual'
            },
            {
                label: 'Semi-Annual',
                value: 'Semi-Annual'
            },
            {
                label: 'Quarterly',
                value: 'Quarterly'
            },
            {
                label: 'Monthly',
                value: 'Monthly'
            },
            {
                label: 'Semi-Monthly',
                value: 'Semi-Monthly'
            },
            {
                label: 'Bi-Weekly',
                value: 'Bi-Weekly'
            },
            {
                label: 'Weekly',
                value: 'Weekly'
            }
        ],        
        slug: 'other_income_period',
        validations: [
            {
                message: "Other Income Period is required",
                test: (value) => value
            }
        ]
    },
    OtherIncomeType: {
        label: 'other income type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Salary',
                value: 'salary'
            },
            {
                label: 'Investment',
                value: 'investment'
            },
            {
                label: 'Support',
                value: 'support'
            },
            {
                label: 'Alimony',
                value: 'alimony'
            },
            {
                label: 'Other Income',
                value: 'otherincome'
            },
            {
                label: 'Car Allowance',
                value: 'car Allowance'
            },
            {
                label: 'Pension',
                value: 'pension'
            },
        ],
        slug: 'other_income_type',
        validations: [
            {
                message: "Other Income Type is required",
                test: (value) => value
            }
        ]
    },
    OtherIncomeAmount: {
        label: 'other income (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'other_income_amount',
        validations: [
            {
                message: "Other Income Amount is required",
                test: (value) => value
            }
        ]
    },
    OtherIncomeDescription: {
        label: 'other income description',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'other_income_description',
          validations: [
            {
                message: "Other Income Description is required",
                test: (value) => value
            }
        ]
    },
    AssetType: {
        label: 'asset type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Savings',
                value: 'savings'
            },
            {
                label: 'PENCOM',
                value: 'pencom'
            },
            {
                label: 'RSA',
                value: 'rsa'
            },
            {
                label: 'Gift',
                value: 'gift'
            },
            {
                label: 'Vehicle',
                value: 'vehicle'
            },
            {
                label: 'Stocks/Bonds/Mutual',
                value: 'stocksbondsmutual'
            },
            {
                label: 'Household Goods',
                value: 'householdgoods'
            },
            {
                label: 'Life Insurance',
                value: 'lifeinsur'
            },
            {
                label: 'Deposit on Purchase',
                value: 'depositonpurch'
            },
            {
                label: 'Mortgage Loan Collateral',
                value: 'mortloancoll'
            },
            {
                label: 'Other',
                value: 'other'
            },
        ],
        slug: 'asset_type',
        validations: [
            {
                message: "Asset Type is required",
                test: (value) => value
            }
        ]
    },
    AssetValue: {
        label: 'asset value (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'asset_value',
        validations: [
            {
                message: "Asset Value is required",
                test: (value) => value
            }
        ]
    },
    AssetDescription: {
        label: 'asset description',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'asset_description',
        validations: [
            {
                message: "Asset Description is required",
                test: (value) => value
            }
        ]
    },
    EmployerStreetName: {
        label: 'employer street name',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Street Name',
        slug: 'street_name',
        validations: [
            {
                message: "Street Name is required",
                test: (value) => value
            }
        ]
    },
    EmployerStreetNumber: {
        label: 'employee street number',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: '20',
        slug: 'employer_street_number',
        validations: [
            {
                message: "Employee Street Number is required",
                test: (value) => value
            }
        ]
    },
    EmployerLocationOrArea: {
        label: 'location / area',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Location',
        slug: 'location_area',
        validations: [
            {
                message: "Location / Area is required",
                test: (value) => value
            }
        ]
    },
    OfficeAddressNumber: {
        label: 'office address number',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Office Address Number',
        slug: 'office_address_number',
        validations: [
            {
                message: "Office Address Number is required",
                test: (value) => value
            }
        ]
    },
    PropertyProvinceOrState: {
        label: 'property state',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [''],
        slug: 'property_state',
        validations: [
            {
                message: "Property Province or State is required",
                test: (value) => value
            }
        ]
    },
    PropertyLGAID: {
        label: 'property lga',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [''],
        slug: 'property_lga',
        validations: [
            {
                message: "Property LGA is required",
                test: (value) => value
            }
        ]
    },
    PropertyLocationOrArea: {
        label: 'property location / area',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: 'Property Location / Area',
        slug: 'property_location_area',
        validations: [
            {
                message: "Property Location / Area is required",
                test: (value) => value
            }
        ]
    },
    TypeOfBuyerID: {
        label: 'type of buyer',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'First Time Buyer',
                value: 'First Time Buyer',
            },
            {
                label: 'Existing Buyer',
                value: 'Existing Buyer'
            }
        ],
        slug: 'type_of_buyer',
                validations: [
            {
                message: "Type of Buyer is required",
                test: (value) => value
            }
        ]
    },
    PropertyTypeID: {
        label: 'property of interest',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: '(SCA) - Standard Self-Contained Apartment',
                value: '(SCA) - Standard Self-Contained Apartment',
            },
            {
                label: '(3DB) - Standard Three-Bedroom Detached Bungalow',
                value: '(3DB) - Standard Three-Bedroom Detached Bungalow',
            },
            {
                label: '(2BF) - Standard Two-Bedroom Block of Flats',
                value: '(2BF) - Standard Two-Bedroom Block of Flats',
            },
            {
                label: '(5DD) - Standard Five-Bedroom Detached Duplex',
                value: '(5DD) - Standard Five-Bedroom Detached Duplex',
            },
            {
                label: '(TDA) - Standard Terrace Duplex Apartment',
                value: '(TDA) - Standard Terrace Duplex Apartment',
            },
            {
                label: '(1BR) - Standard One Bedroom Apartment',
                value: '(1BR) - Standard One Bedroom Apartment',
            },
            {
                label: '(2SDB) - Standard Two- Bedroom Semi-Detached Bungalow',
                value: '(2SDB) - Standard Two- Bedroom Semi-Detached Bungalow',
            },
            {
                label: '(3BF) - Standard Three-Bedroom Block of Flats',
                value: '(3BF) - Standard Three-Bedroom Block of Flats',
            },
            {
                label: '(4SDD) - Standard Four- Bedroom Semi-Detached Duplex',
                value: '(4SDD) - Standard Four- Bedroom Semi-Detached Duplex',
            },
        ],
        slug: 'property_of_interest',
        validations: [
            {
                message: "Property of Interest is required",
                test: (value) => value
            }
        ]
    },
    MortagePropertyAmount: {
        label: 'requested mortgage amount (naira)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'requested_mortgage_amount',
        validations: [
            {
                message: "Requested Mortgage Amount is required",
                test: (value) => value
            }
        ]
    },
    LiabilityAccountNumber: {
        label: 'account number',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'liability_account_number',
        validations: [
            {
                message: "Liability Account Number is required",
                test: (value) => value
            }
        ]
    },
    LiabilityBalance: {
        label: 'balance (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'liability_balance',
        validations: [
            {
                message: "Liability Balance is required",
                test: (value) => value
            }
        ]
    },
    LiabilityMonthlyPayment: {
        label: 'monthly payment (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'liability_monthly_payment',
        validations: [
            {
                message: "Liability Monthly Payment Amount is required",
                test: (value) => value
            }
        ]
    },
    LiabilityType: {
        label: 'liability type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Agriculture Loan',
                value: 'Agricultur'
            },
            {
                label: 'Asset Acquisition Loan',
                value: 'AssetAcquisi'
            },
            {
                label: 'Auto Loan',
                value: 'AutoLoan'
            },
            {
                label: 'Bai Salam Capital Finance',
                value: 'BaiSalam'
            },
            {
                label: 'Bankers Acceptance',
                value: 'BankersAcc'
            },
            {
                label: 'Bonds & Guarantees',
                value: 'Bonds'
            },
            {
                label: 'Commercial Papers',
                value: 'Commercial'
            },
            {
                label: 'Consumption Loan',
                value: 'Consumption'
            },
            {
                label: 'Consumer Durable Loans',
                value: 'ConsumerDurable'
            },
            {
                label: 'Credit Card',
                value: 'CreditCard'
            },
            {
                label: 'Drawings Against Uncleared Effects',
                value: 'DrawingsAgainst'
            },
            {
                label: 'Fast Moving Consumer Goods',
                value: 'FastMoving'
            },
            {
                label: 'Home Loan',
                value: 'HomeLoan'
            },
            {
                label: 'Housing Loan',
                value: 'HousingLoan'
            },
            {
                label: 'Ijarah Asset Finance',
                value: 'IjarahAsset'
            },
            {
                label: 'Istisna Project Finance',
                value: 'IstisnaProject'
            },
            {
                label: 'Least',
                value: 'Least'
            },
            {
                label: 'Letter of Credit',
                value: 'LetterofCredit'
            },
            {
                label: 'Manufacturing Loan',
                value: 'Manufacturing'
            },
            {
                label: 'Mortgage Loan',
                value: 'MortgageLoan'
            },
            {
                label: 'Mudarabah Bank Finance',
                value: 'Mudarabah'
            },
            {
                label: 'Multipurpose Loan',
                value: 'MultipurposeLoan'
            },
            {
                label: 'Murabaha Bank Advance',
                value: 'MurabahaBank'
            },
            {
                label: 'Musharakah Part Finance',
                value: 'MusharakahPart'
            },
            {
                label: 'Overdraft',
                value: 'Overdraft'
            },
            {
                label: 'Personal Loan',
                value: 'PersonalLoan'
            },
            {
                label: 'Service Loan',
                value: 'ServicLoan'
            },
            {
                label: 'Share Purchase Loan',
                value: 'SharePurchaseLoan'
            },
            {
                label: 'Sukuk',
                value: 'Sukuk'
            },
            {
                label: 'Term Loan',
                value: 'TermLoan'
            },
            {
                label: 'Time Loan',
                value: 'TimeLoan'
            },
            {
                label: 'Trade / Commercial Loan',
                value: 'Trade'
            },
            {
                label: 'Revenue Loan',
                value: 'RevenueLoan'
            },
            {
                label: 'Warehousing Facility',
                value: 'Warehousing'
            },
            {
                label: 'Other',
                value: 'other'
            }
        ],
        slug: 'liability_type',
        validations: [
            {
                message: "Liability Type is required",
                test: (value) => value
            }
        ]
    },
    LiabilityDescription: {
        label: 'description',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'liability_description',
        validations: [
            {
                message: "Liability Description is required",
                test: (value) => value
            }
        ]
        
    },
    PropertyStreetNumber: {
        label: 'street number',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'property_street_number',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyStreetName: {
        label: 'street name',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'property_street_name',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyPlot: {
        label: 'property plot',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'property_plot',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyEstate: {
        label: 'estate',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'property_estate',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyType: {
        label: 'type',
        value: '',
        type: 'select',
        type_slug: 'select',
        options: [
            {
                label: 'Bungalow Fully Developed',
                value: 'bungfullydetached'
            },
            {
                label: 'Bungalow Semi-Developed',
                value: 'bungsemidetached'
            },
            {
                label: 'Bungalow Multi-Unit',
                value: 'bungmultiunit'
            },
            {
                label: 'Single Family Detached',
                value: 'singfamdet'
            },
            {
                label: 'Single Family Semi-Detached',
                value: 'singfamsemidet'
            },
            {
                label: 'Single Family Unit in Duplex/Triplex',
                value: 'singfamunitindup'
            },
            {
                label: 'Single Family Unit in Row/Town House',
                value: 'singfamunitinrow'
            },
            {
                label: 'Unit in Apartment Building',
                value: 'unitinbuild'
            },
            {
                label: 'Duplex Fully Detached',
                value: 'duplfully'
            },
            {
                label: 'Duplex Semi-Detached',
                value: 'duplsemi'
            },
            {
                label: 'Duplex Multi-Unit',
                value: 'duplmulti'
            },
            {
                label: 'Triplex Fully Detached',
                value: 'trifully'
            },
            {
                label: 'Triplex Semi-Detached',
                value: 'trisemi'
            },
            {
                label: 'Triplex Multi-Unit',
                value: 'trimulti'
            },
            {
                label: 'Block of Flats Studio',
                value: 'blflstud'
            },
            {
                label: 'Block of Flats One Bedroom',
                value: 'blflone'
            },
            {
                label: 'Block of Flats Two Bedrooms',
                value: 'blfltwo'
            },
            {
                label: 'Block of Flats Three Bedrooms',
                value: 'blflthr'
            },
            {
                label: 'Block of Flats Four Bedrooms',
                value: 'blflfou'
            },
        ],
        slug: 'property_type',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyTenure: {
        label: 'tenure',
        value: '',
        type: 'text',
        type_slug: 'text',
        placeholder: '0',
        slug: 'property_tenure',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyAge: {
        label: 'Age',
        value: '',
        type: 'text',
        type_slug: 'number',
        placeholder: '0',
        slug: 'property_age',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertySpace: {
        label: 'living space',
        value: '',
        type: 'text',
        type_slug: 'number',
        placeholder: '0',
        slug: 'property_space',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyLotSize: {
        label: 'lot size',
        value: '',
        type: 'text',
        type_slug: 'number',
        placeholder: '0',
        slug: 'property_lot_size',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyNoOfRooms: {
        label: 'number of rooms',
        value: '',
        type: 'text',
        type_slug: 'number',
        placeholder: '0',
        slug: 'property_no_of_rooms',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ] 
    },
    PropertyPlan: {
        label: 'plan',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'property_plan',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyAnnualTax: {
        label: 'annual taxes (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_annual_tax',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyEstateServiceCharges: {
        label: 'estate service charge (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_estate_service_charges',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyInsurance: {
        label: 'insurance (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_insurance',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyValuation: {
        label: 'valuation (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_valuation',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyValuationAsIs: {
        label: 'valuation as is (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_valuation_as_is',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyValuationComplete: {
        label: 'valuation complete (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_valuation_complete',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyValuationDate: {
        label: 'valuation date',
        value: '',
        type: 'date',
        type_slug: 'text',
        placeholder: ' ',
        slug: 'property_valuation_date',
        validations: [
            {
                message: "Valuation is required",
                test: (value) => value
            }
        ]
    },
    PropertyValuationForSaleAsIs: {
        label: 'valuation for sale as is (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_valuation_for_sale_as_is',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    PropertyValuationForSaleComplete: {
        label: 'valuation for sale complete (amount)',
        value: '',
        type: 'text',
        type_slug: 'amount',
        slug: 'property_valuation_for_sale_complete',
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    other_income: {
        label: 'type',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'other_income',
    },
    asset: {
        label: 'type',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'asset',
    },
    liabilities: {
        label: 'type',
        value: '',
        type: 'text',
        type_slug: 'text',
        slug: 'liabilities',
    },


    // General Form Fields
    do_you_own: {
        label: 'Do you own a house of your own or are you making contributions/equity payments towards a home of your own?',
        value: '',
        type: 'radio',
        type_slug: 'radio',
        slug: 'do_you_own',
        options: ['Yes', 'No', 'Maybe',],
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    mortgage_familiarity: {
        label: 'Are you familiar with the term Mortgage?',
        value: '',
        type: 'radio',
        type_slug: 'radio',
        slug: 'mortgage_familiarity',
        options: ['Yes', 'No', 'Maybe',],
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    mortgage_consideration: {
        label: 'Do you consider mortgages as a credible means of home ownership?',
        value: '',
        type: 'radio',
        type_slug: 'radio',
        slug: 'mortgage_consideration',
        options: ['Yes', 'No', 'Maybe',],
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    mortgage_application: {
        label: 'Have you ever applied for a mortgage loan?',
        value: '',
        type: 'radio',
        type_slug: 'radio',
        slug: 'mortgage_application',
        options: ['Yes', 'No', 'Maybe',],
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    mortgage_ownership_status: {
        label: 'Do you currently have a mortgage with a bank, developer or cooperative?',
        value: '',
        type: 'radio',
        type_slug: 'radio',
        slug: 'mortgage_ownership_status',
        options: ['Yes', 'No', 'Maybe',],
        validations: [
            {
                message: "Field is required",
                test: (value) => value
            }
        ]
    },
    mortgage_constraints: {
        label: 'What do you consider in your view as a major constraint towards home ownership or getting mortgages?',
        value: [],
        type: 'checkbox',
        type_slug: 'checkbox',
        slug: 'mortgage_constraints',
        options: [
            {
                title: 'High Interest rates by Banks and Financial Institutions',
                value: 'yes',
                slug: 'high_interest_rate',
                parent: 'mortgage_constraints'
            },
            {
                title: 'Lack of Mortgage Products on offer by Financial Institutions',
                value: 'yes',
                slug: 'lack_of_morgage_products',
                parent: 'mortgage_constraints'
            },
            {
                title: 'Lack of Affordable Housing Units by Developers',
                value: 'yes',
                slug: 'lack_of_affordable_housing',
                parent: 'mortgage_constraints'
            },
            {
                title: 'Lack of Land/Title from Government',
                value: 'yes',
                slug: 'lack_of_land_from_govt',
                parent: 'mortgage_constraints'
            },
            {
                title: 'Lack of Information on where and how to access Housing & Mortgages (Market Information)',
                value: 'yes',
                slug: 'lack_of_information',
                parent: 'mortgage_constraints'
            },
        ],
        validations: [
            {
                message: "Field is required",
                test: (value) => value.length
            }
        ]
    },
}
export default fields;
