const AuthLogin = () => import(/* webpackChunkName: "guest" */ '../../views/auth/Login.vue').then(m => m.default || m);
const AuthRegister = () => import(/* webpackChunkName: "guest" */ '../../views/auth/Register.vue').then(m => m.default || m);
const AuthRecoveryPassword = () => import(/* webpackChunkName: "guest" */ '../../views/auth/Recovery.vue').then(m => m.default || m);
const AuthResetPassword = () => import(/* webpackChunkName: "guest" */ '../../views/auth/Reset.vue').then(m => m.default || m);

export default [
    {
      path: 'login',
      name: 'auth.login',
      component: AuthLogin,
      meta: {
        layout: 'bare',
        guest: true
      }
    },
    {
      path: 'register',
      name: 'auth.register',
      component: AuthRegister,
      meta: {
        layout: 'bare',
        guest: true
      }
    },
    {
      path: 'password/recovery',
      name: 'auth.recovery',
      component: AuthRecoveryPassword,
      meta: {
        layout: 'bare',
        guest: true
      }
    },
    {
      path: 'password/reset',
      name: 'auth.reset',
      component: AuthResetPassword,
      meta: {
        layout: 'bare',
        guest: true
      }
    },
]