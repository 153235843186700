import Cookies from 'js-cookie';

// state
export const state = {
    survey: Cookies.get('survey'),
};

// getters
export const getters = {
    showSurvey: state => !!state.survey,    
};

// mutations
export const mutations = {
    UPDATE_SURVEY_STATUS(state, { survey }) {
        state.survey = survey;
    },
};

// actions
export const actions = {
    updateSurveyStatus({ commit }, survey) {
        commit('UPDATE_SURVEY_STATUS', survey);
    },
};
