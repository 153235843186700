<template>
  <section
    class="border p-4 border-success rounded"
  >
    <div
      class="d-flex align-items-center"
      data-animation="fadeInDown"
      data-animation-delay="1.8s"
    >
      <div class="mr-3">
        <img
          :src="require(`../../../assets/icons/check.svg`)"
          alt="check"
          width="100"
          data-animation="fadeInLeft"
          data-animation-delay="1.8s"
        > 
      </div>
      <div
        data-animation="fadeInRight"
        data-animation-delay="1.8s"
      >
        <div
          class="text-justify"
          v-html="message"
        />
        <div class="text-center my-3">
          <router-link
            v-if="action.action === 'go_back'"
            :to="{ name: action.url }"
            :key="$route.fullPath"
            class="btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated text-capitalize"
            data-animation="fadeInDown"
            data-animation-delay="1.8s"
          >
            {{ action.title }}
          </router-link>
          <button
            v-if="action.action === 'close_modal'"
            type="submit"
            class="btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated text-capitalize"
            data-dismiss="modal"
            data-animation="fadeInDown"
            data-animation-delay="1.8s"
            @click="handleCloseModal"
          >
            {{ action.title }}
          </button>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
export default {
    name: 'SuccessMessage',
    props: {
        message: {
            type: String,
            default: 'Thank You',
        },
        action: {
            type: Object,
            default: () => {},
        },
    },

    emits: [ 'close_survey_modal' ],

    methods: {
      handleCloseModal() {
        this.$emit('close_survey_modal')
      },
    }
}
</script>