// Admin Dashboard
const DashboardHome = () =>
  import(
    /* webpackChunkName: "guest" */ "../../views/dashboards/Home.vue"
  ).then((m) => m.default || m);
const HousingDashboard = () =>
  import(
    /* webpackChunkName: "guest" */ "../../views/dashboards/HousingDashboard.vue"
  ).then((m) => m.default || m);
const PricingIndexDashboard = () =>
  import(
    /* webpackChunkName: "guest" */ "../../views/dashboards/PriceIndexDashboard.vue"
  ).then((m) => m.default || m);
const NREDashboard = () =>
  import(
    /* webpackChunkName: "guest" */ "../../views/dashboards/NRE-DCMPDashboard.vue"
  ).then((m) => m.default || m);
const SurveyDashboard = () =>
  import(
    /* webpackChunkName: "guest" */ "../../views/dashboards/HousingSurveyDashboard.vue"
  ).then((m) => m.default || m);

export default [
  {
    path: "home",
    name: "dashboard.home",
    component: DashboardHome,
    meta: {
      layout: "default",
    },
  },
  {
    path: "housing",
    name: "dashboard.housing",
    component: HousingDashboard,
    meta: {
      layout: "default",
    },
  },
  {
    path: "pricing",
    name: "dashboard.pricing",
    component: PricingIndexDashboard,
    meta: {
      layout: "default",
    },
  },
  {
    path: "NRE-DCMP",
    name: "dashboard.nre",
    component: NREDashboard,
    meta: {
      layout: "default",
    },
  },
  {
    path: "housing-Survey",
    name: "dashboard.survey",
    component: SurveyDashboard,
    meta: {
      layout: "default",
    },
  },
];
