// Admin Dashboard
const AdminDashboard = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Dashboard.vue').then(m => m.default || m);

const AdminData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/index.vue').then(m => m.default || m);
const AdminStateData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/State.vue').then(m => m.default || m);
const AdminLGAData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/LGA.vue').then(m => m.default || m);
const AdminLocaitonData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/Locations.vue').then(m => m.default || m);
const AdminIndicatorsData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/Indicators.vue').then(m => m.default || m);
const AdminIndicatorsViewData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/IndicatorsView.vue').then(m => m.default || m);
const AdminDataGroupData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/DataGroup.vue').then(m => m.default || m);
const AdminHomeOwnershipData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/OwnershipType.vue').then(m => m.default || m);
const AdminEnumeratorsData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/Enumerators.vue').then(m => m.default || m);
const AdminEmploymentSectorData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/EmploymentSector.vue').then(m => m.default || m);
const AdminEmploymentStatusData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/EmploymentStatus.vue').then(m => m.default || m);
const AdminBuyerData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/BuyerType.vue').then(m => m.default || m);
const AdminPropertyData = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Data/PropertyType.vue').then(m => m.default || m);

const AdminRoleAndPermissions = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Roles-Permissions/index.vue').then(m => m.default || m);
const AdminRoles = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Roles-Permissions/Role.vue').then(m => m.default || m);
const AdminPermissions = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Roles-Permissions/Permission.vue').then(m => m.default || m);
const AdminPermissionsGroup = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Roles-Permissions/Group.vue').then(m => m.default || m);

const AdminSettings = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Settings.vue').then(m => m.default || m);
const AdminSubscriptions = () => import(/* webpackChunkName: "guest" */ '../../views/admin/Subscriptions.vue').then(m => m.default || m);

export default [
    {
      path: 'dashboard',
      name: 'admin.dashboard',
      component: AdminDashboard,
      meta: {
        layout: 'admin',
      }
    },
    {
      path: 'settings',
      name: 'admin.settings',
      component: AdminSettings,
      meta: {
        layout: 'admin',
      }
    },
    {
      path: 'newsletter/subscription',
      name: 'admin.subscription',
      component: AdminSubscriptions,
      meta: {
        layout: 'admin',
      }
    },
    {
      path: 'data',
      name: 'data',
      component: AdminData,
      redirect: { name: 'admin.data.state' },
      children: [
        {
          path: 'states',
          name: 'admin.data.state',
          component: AdminStateData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'LGA',
          name: 'admin.data.lga',
          component: AdminLGAData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'locations',
          name: 'admin.data.location',
          component: AdminLocaitonData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'indicators',
          name: 'admin.data.indicators',
          component: AdminIndicatorsData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'indicators/view',
          name: 'admin.data.indicators.view',
          component: AdminIndicatorsViewData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'data-group',
          name: 'admin.data.group',
          component: AdminDataGroupData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'employment/sector',
          name: 'admin.data.employment-sector',
          component: AdminEmploymentSectorData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'employment/status',
          name: 'admin.data.employment-status',
          component: AdminEmploymentStatusData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'buyer-type',
          name: 'admin.data.buyer-type',
          component: AdminBuyerData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'property-type',
          name: 'admin.data.property-type',
          component: AdminPropertyData,
          meta: {
            layout: 'admin',
          }
        },
        
        {
          path: 'home-ownership-type',
          name: 'admin.data.home-ownership',
          component: AdminHomeOwnershipData,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'enumerators',
          name: 'admin.data.enumerators',
          component: AdminEnumeratorsData,
          meta: {
            layout: 'admin',
          }
        },
      ],
    },

    {
      path: 'roles',
      name: 'roles',
      component: AdminRoleAndPermissions,
      redirect: { name: 'admin.roles' },
      meta: {
      },
      children: [
        {
          path: 'roles',
          name: 'admin.roles',
          component: AdminRoles,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'permissions',
          name: 'admin.permissions',
          component: AdminPermissions,
          meta: {
            layout: 'admin',
          }
        },
        {
          path: 'permissions/group',
          name: 'admin.permissions.group',
          component: AdminPermissionsGroup,
          meta: {
            layout: 'admin',
          }
        },
      ],
    },
]