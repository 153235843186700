<template>
  <div class="">
    <!-- Page Wrapper -->
    <div id="wrapper">
      <!-- Sidebar -->
      <AdminSidebar />
      <!-- End of Sidebar -->

      <!-- Content Wrapper -->
      <div
        id="content-wrapper"
        class="d-flex flex-column"
      >
        <!-- Main Content -->
        <div id="content">
          <!-- Topbar -->
          <AdminHeader :user="user" />
          <!-- End of Topbar -->

          <!-- Begin Page Content -->
          <main class="container-fluid">
            <slot>
              <router-view />
            </slot>
          </main>
          <!-- /.container-fluid -->
        </div>
        <!-- End of Main Content -->

        <!-- Footer -->
        <AdminFooter />
        <!-- End of Footer -->
      </div>
      <!-- End of Content Wrapper -->
    </div>
    <!-- End of Page Wrapper -->

    <!-- Logout Modal-->
    <LogoutModal />
    <!-- <script
      src="../styles/scripts.js"
      type="application/javascript" -->
    <!-- /> -->
  </div>
</template>

<script>
import { mapState } from 'vuex';
import '../styles/admin/main.css'
import LogoutModal from '../components/Admin/LogoutModal.vue'
import AdminFooter from '../components/Admin/Footer.vue'
import AdminSidebar from '../components/Admin/Sidebar.vue'
import AdminHeader from '../components/Admin/Header.vue'

export default {
  name: "AdminLayout",
  components: { AdminSidebar, LogoutModal, AdminFooter, AdminHeader },

  computed : {
    ...mapState({
      user: state => state.auth.user,
    }),
  },
}
</script>
