import Cookies from 'js-cookie';

// state
export const state = {
    user: null,
    busy: false,
    token: Cookies.get('token'),
};

// getters
export const getters = {
    isAuthenticated: state => !!state.user,    
};

// mutations
export const mutations = {
    setUser(state, email){
        state.user = email
    },

    SAVE_TOKEN(state, { token, remember }) {
        state.token = token;    

        Cookies.set('token', token, {
          domain: process.env.VUE_APP_COOKIE_TOKEN_DOMAIN,
          expires: remember ?? 1,
        });
    },

    UPDATE_BUSY_STATE(state, isBusy) {
        state.busy = isBusy;
    },

    UPDATE_USER(state, { user }) {
        state.user = user;
    },

    LOGOUT(state){
        state.token = null;
        Cookies.remove('token');
        state.user = null;
    },

};

// actions
export const actions = {

    saveToken({ commit }, payload) {
        commit('SAVE_TOKEN', payload);
    },

    updateUser({ commit }, user) {
        commit('UPDATE_USER', user);
    },

    async RegisterUser({dispatch}, form) {
        // await axios.post('User/create', form)
        // let UserForm = new FormData()
        // UserForm.append('Email', form.email)
        // UserForm.append('Password', form.password)
        // await dispatch('LogIn', UserForm)
    },

    // async LogIn({commit}, loginData) {
    //     await axios.post('User/login', loginData)
    //     await commit('setUser', loginData)
    // },

    async logout({ commit }){
        commit('UPDATE_BUSY_STATE', true);

        commit('LOGOUT')
        
        commit('UPDATE_BUSY_STATE', false);
    }
};