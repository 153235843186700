// Homepage
const Home = () => import(/* webpackChunkName: "guest" */ '../../views/Home.vue').then(m => m.default || m);
const About = () => import(/* webpackChunkName: "guest" */ '../../views/About.vue').then(m => m.default || m);
const Auth = () => import(/* webpackChunkName: "guest" */ '../../views/auth/index.vue').then(m => m.default || m);
const Survey = () => import(/* webpackChunkName: "guest" */ '../../views/survey/index.vue').then(m => m.default || m);
const Property = () => import(/* webpackChunkName: "guest" */ '../../views/property/index.vue').then(m => m.default || m);
const ContactUs = () => import(/* webpackChunkName: "guest" */ '../../views/Contact-us.vue').then(m => m.default || m);
const News = () => import(/* webpackChunkName: "guest" */ '../../views/News.vue').then(m => m.default || m);
const NotFound = () => import(/* webpackChunkName: "guest" */ '../../views/NotFound.vue').then(m => m.default || m);

const AdminHome = () => import(/* webpackChunkName: "guest" */ '../../views/admin/index.vue').then(m => m.default || m);
const Dashboards = () => import(/* webpackChunkName: "guest" */ '../../views/dashboards/index.vue').then(m => m.default || m);
const Datasets = () => import(/* webpackChunkName: "guest" */ '../../views/datasets/index.vue').then(m => m.default || m);
const HousingPublic = () => import('../../views/Housing.vue').then(m=>m.default || m);
const HousingData = () => import('../../views/Housing-portal.vue').then(m=>m.default || m);
const PropertyInformation = () => import('../../views/Property-information.vue').then(m=>m.default || m);
const HousingMarket = () => import('../../views/Housing-market.vue').then(m=>m.default || m);


import AuthChildren from './AuthChildren';
import AdminChildren from './AdminChildren';
import SurveyChildren from './SurveyChildren';
import PropertyChildren from './PropertyChildren';
import DashboardChildren from './DashboardChildren';


export default [
    {
      path: '/',
      name: 'root',
      redirect: { name: 'home' },
    },

    {
      path: '/nmrc',
      name: 'home',
      component: Home,
      meta: {
        layout: 'default',
      }
    },

    {
      path: '/contact-us',
      name: 'contact.us',
      component: ContactUs,
      meta: {
        layout: 'default',
      }
    },
   
    
    {
      path: '/news',
      name: 'news',
      component: News,
      meta: {
        layout: 'default',
      }
    },
    {
      path: '/housing-price',
      name: 'housing-price',
      component: HousingPublic,
      meta: {
        layout: 'default',
      }
    },

    {
      path: '/housing-portal',
      name: 'housing.portal',
      component: HousingData,
      meta: {
        layout: 'default',
      }
    },
    {
      path: '/housing-market',
      name: 'housing.market',
      component: HousingMarket,
      meta: {
        layout: 'default',
      }
    },
    {
      path: '/property-own-a-home',
      name: 'property.info',
      component: PropertyInformation,
      meta: {
        layout: 'default',
      }
    },


    {
      path: '/about',
      name: 'about',
      component: About,
      meta: {
        layout: 'bare',
      }
    },

    {
      path: '/auth',
      name: 'auth',
      component: Auth,
      redirect: { name: 'auth.login' },
      children: AuthChildren,
      meta: { type: 'auth-container' },
    },

    {
      path: '/admin',
      name: 'admin',
      component: AdminHome,
      redirect: { name: 'admin.dashboard' },
      children: AdminChildren,
      meta: {
          type: 'admin-container',
          requiresAuth: true
        },
    },

    {
      path: '/dashboard',
      name: 'dashboard',
      component: Dashboards,
      redirect: { name: 'dashboard.home' },
      children: DashboardChildren,
      meta: {
          requiresAuth: true
        },
    },

    {
      path: '/datasets',
      name: 'datasets',
      component: Datasets,
      meta: {
        requiresAuth: true
      },
    },

    {
      path: '/property',
      name: 'property',
      component: Property,
      redirect: { name: 'property.steps' },
      children: PropertyChildren,
      // meta: { type: 'admin-container' },
    },

    {
      path: '/survey',
      name: 'survey',
      component: Survey,
      redirect: { name: 'survey.nredc' },
      children: SurveyChildren,
      // meta: { type: 'survey-container' },
    },

    {
      path: '/*',
      component: NotFound,
      meta: {
        layout: 'bare',
      }
    },
]