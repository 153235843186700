<template>
  <div>
    <b-modal
      id="surveyModal"
      :visible="visible"
      centered
      hide-header
      hide-footer
      size="lg"
      @hide="handleOnHide"
    >
      <div>
        <div v-if="showSurveyForm">
          <general-survey
            @handle_survey_modal="close"
          />
        </div>
        <div
          v-else
          class="p-5"
        >
          <div
            class="text-center animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="0.3s"
          >
            <h6 class="">
              To help NMRC improve the mortgage services and housing market in Nigeria, Kindly participate in this survey, pick the options that best fit your opinion and submit.
              <br>
              <i>Please remember, we seek your honest feedback.</i>
            </h6>
          </div>
          <div
            class="text-center m-3 animated"
            data-animation="fadeInUpShorter"
            data-animation-delay="1.1s"
          >
            <h4 class="my-5 font-weight-bold">
              Would you like to fill the form now?
            </h4>
            <div
              class="d-flex justify-content-center animated"
              data-animation="fadeInUpShorter"
              data-animation-delay="0.3s"
            >
              <button
                type="submit"
                class="mx-3 btn btn-lg btn-warning btn-glow float-right "
                @click="handleHideSurveyForm"
              >
                No, Thanks!
              </button>
              <button
                class="mx-3 btn btn-lg btn-gradient-purple btn-glow float-right"
                @click="handleShowSurveyForm"
              >
                Yes, Please!
              </button>
            </div>
          </div>
        </div>
      </div>
    </b-modal>
  </div>
</template>
<script>
import GeneralSurvey from '../Survey/GeneralSurvey.vue'
import { createNamespacedHelpers } from 'vuex';
const generalAccess = createNamespacedHelpers('general');

export default {
    name: 'SurveyModal',
    components: { GeneralSurvey },

    props: {
      visible: {
          type: Boolean,
          default: false,
      },
    },

  emits: ['close-survey-modal'],

  data () {
    return {
      showSurveyForm: false,
    };
  },

  computed : {
    ...generalAccess.mapGetters([
      'showSurvey',
    ]),
  },

  mounted() {
    if(!this.showSurvey) {
      setTimeout(() => this.showModal(), 15000);
    }
  },
  
  methods: {
    close() {
      this.$bvModal.hide('surveyModal');
    },

    showModal() {
      this.$bvModal.show('surveyModal');
    },

    handleOnHide() {
      this.$emit('close-survey-modal');
    },

    handleHideSurveyForm() {
      this.$bvModal.hide('surveyModal');
      this.$store.dispatch('general/updateSurveyStatus', {
        survey: true,
      });
    },

    handleShowSurveyForm() {
      this.showSurveyForm = !this.showSurveyForm;
    }
  },
  
}
</script>