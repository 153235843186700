<template>
  <div id="app">
    <component :is="currentLayoutComponent">
      <!-- <slot> -->
      <router-view />
      <!-- </slot> -->
    </component>
  </div>
</template>

<script>
import { mapState, mapGetters } from 'vuex';

export default {


    computed: {
    ...mapState({
      ready: state => state.layout.ready,
      currentLayout: state => state.layout.current,
    }),
    ...mapGetters({
      currentLayoutComponent: 'layout/currentLayoutComponentName',
    }),
  },

  methods: {

  },
}
</script>
<style>
#app {
  padding: 0;
  margin: 0;
}
</style>
