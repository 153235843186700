<template>
  <div class="admin-sidebar">
    <ul
      id="accordionSidebar"
      class="navbar-nav bg-gradient sidebar sidebar-dark accordion"
    >
      <!-- Sidebar - Brand -->
      <router-link
        :to="{ name: 'admin.dashboard' }"
        class="sidebar-brand d-flex align-items-center justify-content-center"
      >
        <div class="sidebar-brand-text mx-3">
          HMIP | NMRC
        </div>
      </router-link>

      <!-- Divider -->
      <hr class="sidebar-divider my-0">

      <!-- Nav Item - Dashboard -->
      <li class="nav-item active">
        <router-link
          :to="{ name: 'admin.dashboard' }"
          class="nav-link"
        >
          <i class="fas fa-fw fa-tachometer-alt" />
          <span>Dashboard</span>
        </router-link>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider">

      <!-- Heading -->
      <div class="sidebar-heading">
        Data
      </div>

      <!-- Nav Item - Pages Collapse Menu - Data Entry -->
      <li class="nav-item">
        <a
          class="nav-link collapsed"
          href="#"
          data-toggle="collapse"
          data-target="#collapseDataEntry"
          aria-expanded="true"
          aria-controls="collapseDataEntry"
        >
          <i class="fas fa-fw fa-folder" />
          <span>Data Entry</span>
        </a>
        <div
          id="collapseDataEntry"
          class="collapse"
          aria-labelledby="headingDataEntry"
          data-parent="#accordionSidebar"
        >
          <div class="bg-white py-2 collapse-inner rounded">
            <router-link
              :to="{ name: 'admin.data.state' }"
              class="collapse-item"
            >
              States
            </router-link>
            <router-link
              :to="{ name: 'admin.data.lga' }"
              class="collapse-item"
            >
              LGA
            </router-link>
            <router-link
              :to="{ name: 'admin.data.location' }"
              class="collapse-item"
            >
              Location
            </router-link>
            <router-link
              :to="{ name: 'admin.data.group' }"
              class="collapse-item"
            >
              Data Group
            </router-link>
            <router-link
              :to="{ name: 'admin.data.home-ownership' }"
              class="collapse-item"
            >
              Ownership Type
            </router-link>
            <router-link
              :to="{ name: 'admin.data.enumerators' }"
              class="collapse-item"
            >
              Enumerators
            </router-link>
            <router-link
              :to="{ name: 'admin.data.employment-sector' }"
              class="collapse-item"
            >
              Employment Sector
            </router-link>
            <router-link
              :to="{ name: 'admin.data.employment-status' }"
              class="collapse-item"
            >
              Employment Status
            </router-link>
            <router-link
              :to="{ name: 'admin.data.buyer-type' }"
              class="collapse-item"
            >
              Buyer Type
            </router-link>
            <router-link
              :to="{ name: 'admin.data.property-type' }"
              class="collapse-item"
            >
              Property Type
            </router-link>
            <router-link
              :to="{ name: 'admin.data.indicators' }"
              class="collapse-item"
            >
              Indicators
            </router-link>
            <router-link
              :to="{ name: 'admin.data.indicators.view' }"
              class="collapse-item"
            >
              Indicators View
            </router-link>
          </div>
        </div>
      </li>

      <!-- Nav Item - Pages Collapse Menu - Roles & Permissions -->
      <li class="nav-item">
        <a
          class="nav-link collapsed"
          href="#"
          data-toggle="collapse"
          data-target="#collapseRoles"
          aria-expanded="true"
          aria-controls="collapseRoles"
        >
          <i class="fas fa-fw fa-cogs" />
          <span>Roles & Permissions</span>
        </a>
        <div
          id="collapseRoles"
          class="collapse"
          aria-labelledby="headingRoles"
          data-parent="#accordionSidebar"
        >
          <div class="bg-white py-2 collapse-inner rounded">
            <router-link
              :to="{ name: 'admin.permissions.group' }"
              class="collapse-item"
            >
              Permission Groups
            </router-link>
            <router-link
              :to="{ name: 'admin.permissions' }"
              class="collapse-item"
            >
              Permissions
            </router-link>
            <router-link
              :to="{ name: 'admin.roles' }"
              class="collapse-item"
            >
              Roles
            </router-link>
          </div>
        </div>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider">

      <!-- Heading -->
      <div class="sidebar-heading">
        Manage
      </div>

      <li class="nav-item d-none">
        <router-link
          :to="{ name: 'admin.subscription' }"
          class="nav-link"
        >
          <i class="fas fa-fw fa-list" />
          <span>Newsletter Subscription</span>
        </router-link>
      </li>
      <li class="nav-item">
        <router-link
          :to="{ name: 'admin.settings' }"
          class="nav-link"
        >
          <i class="fas fa-fw fa-cog" />
          <span>Settings</span>
        </router-link>
      </li>
      <li
        class="nav-item"
      >
        <b-button
          class="nav-link"
          variant="link"
          @click="logout"
        >
          <i class="fas fa-fw fa-power-off" />
          <span>Logout</span>
        </b-button>
      </li>

      <!-- Divider -->
      <hr class="sidebar-divider d-none d-md-block">

      <div class="sidebar-card d-none d-lg-flex">
        <i class="fa fa-arrow-up fa-2x mb-2" />
        <router-link
          :to="{ path: '/' }"
          class="btn btn-success btn-sm"
          target="_blank"
        >
          <span>Main Site</span>
        </router-link>
      </div>
    </ul>
  </div>
</template>

<script>

export default {
    name: 'AdminSidebar',
    data: function() {
      return {}
    },

    methods: {
      async logout() {
        await this.$store.dispatch('auth/logout')
        this.$router.push({ name: 'home' });
      }
     
    },
}
</script>