import Vue from 'vue';
import { reduce, camelCase, upperFirst } from 'lodash';

export default () => {
  const requireComponent = require.context('./', true, /\.(vue|js)$/);
  requireComponent.keys().forEach(fileName => {
    if (fileName === './register.js') return;
    const componentConfig = requireComponent(fileName);
    const name = fileName.replace(/\.\w+$/, '');

    const componentName = String(
      reduce(
        name.split('/'),
        (prev, partName) => {
          return `${prev}${upperFirst(camelCase(partName))}`;
        },
        'Layout',
      ),
    ).replace(/\.\w+$/, '');

    Vue.component(componentName, componentConfig.default || componentConfig);
  });
};
