<template>
  <header class="page-header">
    <!-- Horizontal Menu Start-->
    <nav class="main-menu static-top navbar-dark navbar navbar-expand-lg fixed-top mb-1">
      <div class="container px-0">
        <router-link
          :to="{ path: '/' }"
          class="navbar-brand animated"
          data-animation="fadeInDown"
          data-animation-delay="1s"
        >
          <img
            src="~@/assets/theme-assets/images/logo.png"
            
            alt="HMIP Logo"
            style="float:left; max-width: 170px;"
          >
          <div style="line-height:11px; margin-top:35px">
            <span style="font-size:10px">Housing Market</span><br>
            <span style="font-size:10px">Information Portal</span>
          </div>
        </router-link>
        <button
          class="navbar-toggler"
          type="button"
          data-toggle="collapse"
          data-target="#navbarCollapse"
          aria-controls="navbarCollapse"
          aria-expanded="false"
          aria-label="Toggle navigation"
        >
          <span class="navbar-toggler-icon" />
        </button>
        <div
          id="navbarCollapse"
          class="collapse navbar-collapse"
        >
          <div
            id="navigation"
            class="navbar-nav ml-auto"
          >
            <ul class="navbar-nav mt-1">
              <li
                class="dropdown show animated"
                data-animation="fadeInDown"
                data-animation-delay="1.1s"
              >
                <a
                  href="#"
                  class="dropdown-toggle white cursor-pointer"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Property Information
                </a>
                <div
                  class="dropdown-menu"
                  aria-labelledby="more"
                >
                  <router-link
                    :to="{ name: 'property.info' }"
                    class="dropdown-item"
                  >
                    Own a Home
                  </router-link>
                 
                  <router-link
                    :to="{ name: 'own.home' }"
                    class="dropdown-item"
                  >
                    Steps to Own a Home
                  </router-link>
                  <!-- <router-link
                    :to="{ name: 'submit.property' }"
                    class="dropdown-item"
                  >
                    Submit a Property
                  </router-link> -->
                  <!-- <router-link
                    :to="{ name: 'fhf.calculator' }"
                    class="dropdown-item"
                  >
                    FHF Calculator
                  </router-link> -->
                  <router-link
                    :to="{ name: 'mortgage.calculator' }"
                    class="dropdown-item"
                  >
                    Detailed Mortgage Calculator
                  </router-link>                  
                </div>
              </li>

              <li
                class="dropdown show mr-2 animated"
                data-animation="fadeInDown"
                data-animation-delay="1.3s"
              >
                <a
                  href="#"
                  class="dropdown-toggle white cursor-pointer"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Housing Data
                </a>
                <div
                  class="dropdown-menu"
                  aria-labelledby="more"
                >
                  <router-link
                    :to="{ name: 'housing.portal' }"
                    class="dropdown-item"
                  >
                    Housing Data
                  </router-link>
                  <router-link
                    :to="{ name: 'dashboard' }"
                    class="dropdown-item"
                  >
                    Dashboards
                  </router-link>
                  <router-link
                    :to="{ name: 'datasets' }"
                    class="dropdown-item"
                  >
                    Datasets
                  </router-link>
                </div>
              </li>

              <li
                class="nav-item animated"
                data-animation="fadeInDown"
                data-animation-delay="1.5s"
              >
                <router-link
                  :to="{ name: 'housing.market' }"
                  class="nav-link"
                >
                  Housing Directory
                </router-link>
              </li>

              <li
                class="dropdown show mr-2 animated"
                data-animation="fadeInDown"
                data-animation-delay="1.7s"
              >
                <a
                  href="#"
                  class="dropdown-toggle white cursor-pointer"
                  data-toggle="dropdown"
                  aria-haspopup="true"
                  aria-expanded="false"
                >
                  Survey
                </a>
                <div
                  class="dropdown-menu"
                  aria-labelledby="more"
                >
                <a
                    href="/survey/AbujaHousing"
                    class="dropdown-item"
                  >
                    Abuja Housing Survey
                  </a>
                  <router-link
                    :to="{ name: 'survey.own.home' }"
                    class="dropdown-item"
                  >
                    Own a Home Survey
                  </router-link> 
                  <!-- <router-link
                    :to="{ name: 'survey.nredc' }"
                    :key="$route.fullPath"
                    @click.native="$router.go()"
                    class="dropdown-item"
                  >
                    NREDC Survey
                  </router-link> -->
                  <a
                    href="/survey/NREDC"
                    class="dropdown-item"
                  >
                    NREDC Survey
                  </a>
                  <a
                    href="/survey/REDAN"
                    class="dropdown-item"
                  >
                    REDAN Survey
                  </a>
                
                  <!-- 
                  <router-link
                    :to="{ name: 'survey.ekiti' }"
                    class="dropdown-item"
                  >
                    Ekiti State Housing Survey 
                  </router-link> -->
                </div>
              </li>
            
              <li
                class="nav-item animated"
                data-animation="fadeInDown"
                data-animation-delay="1.5s"
              >
                <router-link
                  :to="{ name: 'news' }"
                  class="nav-link"
                >
                  News
                </router-link>
              </li>
              <li
                class="nav-item animated"
                data-animation="fadeInDown"
                data-animation-delay="1.6s"
              >
                <router-link
                  :to="{ name: 'contact.us' }"
                  class="nav-link"
                >
                  Contact Us
                </router-link>
              </li>
            </ul>
            <span id="slide-line" />
            <router-link
              v-if="isAdmin"
              :to="{ name: 'admin.dashboard' }"
              target="_blank"
              class="btn btn-sm btn-gradient-purple btn-glow mr-3 my-2 my-sm-0 animated"
              data-animation="fadeInDown"
              data-animation-delay="1.8s"
            >
              Admin Dashboard
            </router-link>
            <button
              v-else-if="isAuthenticated"
              class="btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated"
              data-animation="fadeInDown"
              data-animation-delay="1.9s"
              @click.prevent="logout"
            >
              <b-spinner
                v-if="authBusy"
                class="mr-1"
                small
              />
              Logout
            </button>
            <router-link
              v-else
              :to="{ name: 'auth.login' }"
              class="btn btn-sm btn-gradient-purple btn-glow my-2 my-sm-0 animated"
              style="border: 1px solid #fff"
              data-animation="fadeInDown"
              data-animation-delay="1.9s"
            >
              Sign In
            </router-link>
          </div>
        </div>
      </div>
    </nav>
    <!-- /Horizontal Menu End-->
  </header>
</template>

<script>
import { mapState, createNamespacedHelpers } from 'vuex';
const authAccess = createNamespacedHelpers('auth');
const accessControl = createNamespacedHelpers('accessControl');

export default {
  name: 'Header',

  emits: ['toggle-survey-modal'],

  data() {
    return {
    }
  },

  computed : {
    ...mapState({
      user: state => state.auth.user,
      authBusy: state => state.auth.busy,
    }),

    ...authAccess.mapGetters([
      'isAuthenticated',
    ]),

    ...accessControl.mapGetters([
      'isAdmin',
    ]),

  },

  methods: {
    handleSurveyModal() {
      this.$emit('show-survey-modal')
    },

    async logout() {
      await this.$store.dispatch('auth/logout')
      this.$router.push({ name: 'home' });
    }
  }
}
</script>
<style scoped>
.navbar {
  background-color: #373435;
  opacity: 0.9;
}
</style>