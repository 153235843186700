<template>
  <cookie-consent>
    <template
      #message
    >
      <h5><b>NMRC PRIVACY POLICY</b></h5>
      <p class="px-5">
        Personal Data uploaded on this Portal shall be used for the purpose of taking surveys and collation of statistics by NMRC. By accessing HMIP, the user acknowledges and agrees that NMRC as Data Controller may collect, process, retain, or disclose the user’s information or any information by the user  for the effectiveness of service provided by NMRC, and the collected, processed, retained or disclosed information shall comply with the Nigeria Data Protection Regulations and any regulations, laws or rules applicable in Nigeria from time to time. 
        Also, note that data collated on this Portal may be retained for a period not exceeding six (6) years.
      </p>
      <button
        type="button"
        class="btn btn-primary btn-sm mr-2"
        onclick="window.open('https://nmrc.com.ng/wp-content/uploads/2019/11/NMRC-Privacy-Policy.pdf', 'resizable=yes')"
      >
        Download
      </button>
                    &nbsp;
      <button
        type="button"
        class="btn btn-primary btn-sm mr-2"
        onclick="window.open('https://nmrc.com.ng/privacy-policy-of-the-nigeria-mortgage-refinance-company-plc/', 'resizable=yes')"
      >
        Read
      </button>
    </template>
    <template
      #button
    >
      <button class="btn btn-primary btn-sm">
        Accept
      </button>
    </template>
  </cookie-consent>
</template>

<script>

import CookieConsent from 'vue-cookieconsent-component'
export default {
    name: 'Cookie',
    components: {
      CookieConsent
    },
}
</script>