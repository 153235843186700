import Vue from "vue";
import App from "./App.vue";
import router from "./router";
import store from "./store";
import axios from "axios";
import * as VueGoogleMaps from "vue2-google-maps"; // Import package
import { BootstrapVue } from "bootstrap-vue";
import VueSweetalert2 from "vue-sweetalert2";
import Vuelidate from "vuelidate";
import VueContentPlaceholders from "vue-content-placeholders";
import cookieconsent from "vue-cookieconsent-component";
import excel from "vue-excel-export";

import "vue-awesome-paginate/dist/style.css";

import "./directives";
import "./components";
import "bootstrap/dist/css/bootstrap.css";
import "bootstrap-vue/dist/bootstrap-vue.css";
import "./scss/app.scss";
import "./scss/main.scss";
import "sweetalert2/dist/sweetalert2.min.css";

import registerLayoutComponents from "./layouts/register";
registerLayoutComponents();

import Raphael from "raphael/raphael";
global.Raphael = Raphael;

axios.defaults.withCredentials = true;

axios.defaults.baseURL = process.env.VUE_APP_API_URL;
// axios.defaults.baseURL = 'https://hmip.herokuapp.com/'; // update this to read from env
axios.defaults.headers.common[
  "Authorization"
] = `Bearer ${store.state.auth.token}`;

axios.interceptors.response.use(undefined, (error) => {
  if (error) {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;
      store.dispatch("auth/logout");
      return router.push("/auth/login");
    }
    return Promise.reject(error.response);
  }
});

Vue.use(BootstrapVue);
Vue.use(VueSweetalert2);
Vue.use(Vuelidate);
Vue.use(excel);
Vue.use(VueContentPlaceholders);
Vue.component(cookieconsent);
Vue.use(VueGoogleMaps, {
  load: {
    key: process.env.VUE_APP_GOOGLE_API_KEY,
    libraries: "places",
  },
});

Vue.config.productionTip = false;

const HMIP = new Vue({
  router,
  store,
  ...App,
}).$mount("#app");

export default HMIP;
