<template>
  <div
    data-menu-open="hover"
    data-menu=""
  >
    <PageLoading />
  </div>
</template>

<script>
import PageLoading from '../components/PageLoading.vue';

export default {
  name: 'LoaderLayout',
  components: { PageLoading },
};
</script>
