<template>
  <nav class="navbar navbar-expand navbar-light bg-white topbar mb-4 static-top shadow">
    <!-- Sidebar Toggle (Topbar) -->
    <button
      id="sidebarToggleTop"
      class="btn btn-link d-md-none rounded-circle mr-3"
    >
      <i class="fa fa-bars" />
    </button>

    <div class="h5">
      Welcome to Admin Center 
    </div>
    <!-- <div v-if="items">
      <b-breadcrumb :items="items" />
    </div> -->

    <!-- Topbar Navbar -->
    <ul class="navbar-nav ml-auto">
      <div class="topbar-divider d-none d-sm-block" />

      <!-- Nav Item - User Information -->
      <li class="nav-item dropdown no-arrow">
        <router-link
          :to="{ name: 'admin.settings' }"
          class="nav-link"
        >
          <span class="mr-2 d-none d-lg-inline text-gray-600 text-capitalize">
            {{ user.Firstname }} {{ user.Lastname }}
          </span>
          <b-avatar
            variant="success"
            :text="getInitials(user)"
          />
        </router-link>
      </li>
    </ul>
  </nav>
</template>

<script>
  export default {
    name: 'AdminHeader',
    props: {
      user: {
          type: Object,
          default: () => {},
          required: true
      },
    },
    
    data: function() {
      return {}
    },

    methods: {
      getInitials(user) {
        let first = user.Firstname.charAt(0);
        let last = user.Lastname.charAt(0);
        let initials = first + last;

        return initials.toUpperCase();
      },
    }
}
</script>